.content {
  padding-bottom: 100px;
  .card {
  }
  .checkboxes-wrapper {
    padding-top: 10px;
  }

  a {
    color: #000;
    text-decoration: underline;
  }

  .bottom-para {
    font-size: 14px;
  }
}
