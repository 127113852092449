ul.skip-navigation {
	@include reset_ul;
	li {
		a {
			position: absolute;
			z-index: 10;
			left: 0px;
			top: -30px;

			font-family: $font2;
			color: $black;
			display: inline-block;
			text-decoration: none;
			padding: 5px 7px;

			&:focus {
				top: 0px;
				background-color: #000;
				color: #fff;
			}
		}
	}
}
