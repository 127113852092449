:root {
  font-size: $base_font_size;
}

body {
  font-family: $font1;
  color: $black;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $font2;
  font-weight: $fw-bold;
  text-transform: uppercase;
}

*:focus,
a:focus,
a:active {
  /* outline: none; */
  color: inherit;
  text-decoration: inherit;
}

br {
  font-size: inherit;
  line-height: inherit;
}

section {
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

i {
  color: inherit;
  font-size: inherit;
}

strong {
  font-size: inherit;
  line-height: inherit;
}

iframe {
  max-width: 100%;
}

svg {
  display: inline-block;
}
