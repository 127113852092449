input,
button,
select,
textarea,
.form-control {
  font-family: $font1;
  line-height: normal;
  font-size: 14px;
}

.form-control {
  @include border-radius(3px);
  height: 50px;
  border: none;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 2px 0 rgb(0 0 0 / 10%);

  &:focus {
    /* border-color: #000;
    outline: 0; 
    box-shadow: none;*/
  }

  &.wpcf7-not-valid {
    border-color: red;

    & + .wpcf7-not-valid-tip {
      text-align: left;
    }
  }
}

textarea.form-control {
  height: auto;
}

.the-button {
  @include reset_button;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  padding: 0px 20px;

  color: $black !important;
  text-decoration: none !important;
  background-color: #ccc;

  &:hover {
    background-color: #000;
    color: #fff !important;
  }
}

/****** Bootstrap buttons *******/
.btn {
  border-radius: 5px;
  text-transform: uppercase;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 20px;
  letter-spacing: 3px;
  font-weight: $fw-semibold;
}

.btn-primary {
  @include button-variant(#000, #000, darken(#000, 10%), darken(#000, 10%), darken(#000, 15%), darken(#000, 15%));
}

.btn-outline-primary {
  @include button-outline-variant(#000, #fff, #000, #000);
}

/* 
.btn-secondary {
  @include button-variant(#fff, #fff, darken(#fff, 10%), darken(#fff, 10%), darken(#fff, 15%), darken(#fff, 15%));
  color: $blue;

  &:hover {
    color: $blue;
  }
}
.btn-default {
  &:hover {
    opacity: 0.8;
  }
} */
