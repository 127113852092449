@mixin debug($color: red) {
	border: 1px solid $color;
}

@mixin box($width, $height) {
	width: $width;
	height: $height;
}

@mixin border-radius($radius) {
	border-radius: $radius;
	background-clip: padding-box;
}

@mixin absolute($top: auto, $right: auto, $bottom: auto, $left: auto) {
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
	position: absolute;
}

@mixin vertical-align {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

@mixin horizontal-align {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

@mixin vertical-horizontal-align {
	@include vertical-align;
	left: 0px;
	right: 0px;
	margin-left: auto;
	margin-right: auto;
}

@mixin flex {
	display: flex;
	flex-wrap: wrap;
}

@mixin flex-start {
	justify-content: flex-start;
}

@mixin flex-end {
	justify-content: flex-end;
}

@mixin flex-center {
	justify-content: center;
}

@mixin flex-space-between {
	justify-content: space-between;
}

@mixin flex-space-around {
	justify-content: space-around;
}

@mixin transition($length: 0.25s, $what: all, $easing: ease-in-out) {
	transition: $what $length $easing;
}

@mixin hidden() {
	opacity: 0;
	visibility: hidden;
	@include transition;
}

@mixin visible() {
	opacity: 1;
	visibility: visible;
	@include transition;
}

@mixin reset_button() {
	padding: 0px;
	border: none;
	background: none;
	@include border-radius(0px);
	text-align: center;
	cursor: pointer;
	/* &:focus {
		outline: 0;
	} */
}

@mixin reset_ul() {
	margin: 0px;
	padding: 0px;
	@include flex;
	@include flex-start;
	li {
		list-style: none;
	}
}

@mixin reset_ul_block() {
	margin: 0px;
	padding: 0px;
	display: block;
	li {
		list-style: none;
	}
}

@mixin svg_icon($width, $height, $url) {
	display: inline-block;
	background: #000;
	place-items: center center;
	-webkit-mask-size: cover;
	mask-size: cover;
	width: $width;
	height: $height;
	-webkit-mask: url($url);
	mask: url($url);
}
/* 
Usage:
.icon-instagram{
	@include svg_icon(30px, 30px, ../img/i_instagram.svg);	
} 
*/

@mixin toRem($property, $value) {
	#{$property}: ($value / $base_font_size) + rem;
}
