$base_font_size: 16px;

/*
 * Colors
 */
$black: #000000;
$red: #ce0d0d;

$colors: (
	black: $black,
	red: $red,
);

// Generate color classes e.g. bg-red, text-red
@mixin gen-props($prefix, $property) {
	@each $color-name, $color in $colors {
		.#{'' + $prefix}-#{'' + $color-name} {
			#{$property}: $color;
		}
	}
}

@include gen-props('text', 'color');
@include gen-props('bg', 'background');
